import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/xampp/htdocs/hausman/app/src/client/template.js";
import SymbolSvg from "../assets/images/site-branding/symbol.inline.svg";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnimateIntroBanner = makeShortcode("AnimateIntroBanner");
const RowLines = makeShortcode("RowLines");
const Block = makeShortcode("Block");
const Heading = makeShortcode("Heading");
const Paragraph = makeShortcode("Paragraph");
const Row = makeShortcode("Row");
const Contact = makeShortcode("Contact");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <AnimateIntroBanner id="banner" className="background_logo light" background={<><RowLines mdxType="RowLines" /><SymbolSvg mdxType="SymbolSvg" /></>} mdxType="AnimateIntroBanner">
  <Block mdxType="Block">
    <Heading className="intro_from_below" mdxType="Heading">
      Vendors
    </Heading>
  </Block>
  <Block className="content_block intro_from_below" mdxType="Block">
    <Paragraph mdxType="Paragraph">We aim to ensure the highest quality at the best value for our client’s needs. If this aligns with your vision, get in touch to collaborate. </Paragraph>
  </Block> 
    </AnimateIntroBanner>
    <Row id="contact_form" background={true} data-background-animation="false" mdxType="Row">
  <Block mdxType="Block">
    <Heading className="title" level={3} mdxType="Heading">Contact Form</Heading>
    <Contact mdxType="Contact" />
  </Block>
    </Row>
    <Row className="whitespace" background={<RowLines mdxType="RowLines" />} mdxType="Row" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      